
import { useLogin } from "../../hooks/useLogin";
import "./style.css";

const Login = ({ onLogin, transactionDetails, onTronLogin }) => {
  const { state: { isWalletInstalled, isConnecting }, action: { onLoginHandler, cancelTransaction, onTronConnect } } = useLogin(onLogin, transactionDetails, onTronLogin)
  return (
    <>
      {(isWalletInstalled && transactionDetails?.Network !== "trc20") && (
        <>
          <button
            type="button"
            className="btn btn-outline-success"
            onClick={onLoginHandler}
          >
            {!isConnecting && "Wallet Connect"}
            {isConnecting && "Connecting..."}
          </button>
        </>
      )
      }
      {transactionDetails?.Network === "trc20" && (<button
        type="button"
        className="btn btn-outline-success"
        onClick={onTronConnect}
      >
        {!isConnecting && "Tron Connect"}
        {isConnecting && "Connecting..."}
      </button>)
      }
      <button
        type="button"
        className="btn btn-outline-danger"
        onClick={cancelTransaction}
      >
        Cancel Transaction
      </button>
      {
        !isWalletInstalled && transactionDetails?.Network !== "trc20" && (
          <>
            <p>
              Wallet Not Detected
            </p>
            <p>
              Please Install
              <a href="https://metamask.io/download/">MetaMask</a>
              <a href="https://chrome.google.com/webstore/detail/tronlink/ibnejdfjmmkpcnlpebklmnkoeoihofec">Tron Link</a>
            </p>
          </>
        )
      }
    </>
  );
};

export default Login;