import UseTransaction from "./hooks/useTransaction";
import MainDetails from "./MainDetails/MainDetails";

const App = () => {
  const {
    state: { isConnected, currentAccount, chainId, transactionDetails },
    action: { onLogin, onLogout, getCurrentNetwork, onTronLogin } } = UseTransaction()
  return (
    <>
      <MainDetails
        onTronLogin={onTronLogin}
        isConnected={isConnected}
        currentAccount={currentAccount}
        onLogin={onLogin}
        onLogout={onLogout}
        getCurrentNetwork={getCurrentNetwork}
        chainId={chainId}
        transactionDetails={transactionDetails}
      />
    </>
  );
}

export default App;