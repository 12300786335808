import { ethers } from "ethers";
import { useState } from "react";
import axios from "axios";
import Web3 from "web3";
import { Errors } from './constant'

const useInitiateTransaction = (value, CurrentAccount, toAddr, requiredChainId, requiredNetwork, ContractAddress, tokenName, abiJson, successUrl, failedUrl, decimalPlaces) => {
    const [error, setError] = useState();
    const [txs, setTxs] = useState([]);
    const [transactionStatus, setTransactionStatus] = useState()
    const [transactionCompleteResponse, setTransactionCompleteResponse] = useState()
    const [pending, setPending] = useState(false);
    let tokenAbi;
    const POST_URL = process.env.REACT_APP_POST_API_URL
    let web3;
    const addNetwork = async (requiredNetwork) => {
        try {
            await window.ethereum.request({
                method: 'wallet_addEthereumChain',
                params: [
                    requiredNetwork
                ]
            });
        } catch (err) {
            console.log(err, "this is eror")
            console.log(`error ocuured while adding new chain with chainId:${requiredNetwork.chainId}, err: ${err.message}`)
        }
    }

    const onClick = () => {
        setError()
    }

    const switchNetwork = async (chainId) => {
        try {
            await window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: Web3.utils.toHex(chainId) }]
            });
            console.log(`switched to chainid : ${chainId} succesfully`);
        } catch (err) {
            console.log(`error occured while switching chain to chainId ${chainId}, err: ${err.message} code: ${err.code}`);
            if (err.code === 4902) {
                addNetwork(requiredNetwork);
            }
        }
    }
    const addToken = async () => {
        const tokenAddress = ContractAddress;
        const tokenSymbol = tokenName;
        const tokenDecimals = 18;
        try {
            await window.ethereum.request({
                method: 'wallet_watchAsset',
                params: {
                    type: 'ERC20',
                    options: {
                        address: tokenAddress,
                        symbol: tokenSymbol,
                        decimals: tokenDecimals,
                    },
                },
            });
        } catch (error) {
            console.log(error);
        }
    }
    const processTransaction = async ({ setError, setTxs, ether, addr }) => {
        try {
            if (!window.ethereum)
                throw new Error("No crypto wallet found. Please install it.");
            web3 = new Web3(window.ethereum);
            const currentChainId = await web3.eth.getChainId();
            if (currentChainId !== Web3.utils.hexToNumber(requiredChainId)) {
                await switchNetwork(Web3.utils.hexToNumber(requiredChainId))
            }
            const provider = new ethers.providers.Web3Provider(window.ethereum);
            const signer = provider.getSigner();
            ethers.utils.getAddress(addr);
            const customTokenContract = new web3.eth.Contract(abiJson, ContractAddress);
            tokenAbi = customTokenContract
            const amt = web3.utils.toBN(Number(ether) * 10 ** decimalPlaces);
            const transferFunction = tokenAbi?.methods?.transfer(addr, amt);
            const encodedABI = transferFunction.encodeABI();
            const tx = await signer.sendTransaction({
                to: ContractAddress,
                data: encodedABI
            });
            setTxs([tx]);
            checkTransactionStatus(tx);
        } catch (err) {
            setPending(false)
            if (err.code === 'INSUFFICIENT_FUNDS') {
                setError(Errors.INSUFFICIENT_FUNDS)
            }
            if (err.code === "ACTION_REJECTED") {
                setError(Errors.ACTION_REJECTED)
            }
            if (err.code === "UNPREDICTABLE_GAS_LIMIT") {
                setError(err.error)
            }
        }
    };

    const sendTrc20Token = async (account) => {
        try {
            const tronWeb = window.tronWeb;
            var receiverAddress = toAddr;
            var amount = (value * (10 ** decimalPlaces));
            const contractAddress = ContractAddress;
            const contract = await tronWeb.contract().at(contractAddress);
            setPending(true);
            const transaction = await contract.transfer(receiverAddress, amount).send();
            checkTransactionStatusOfTrc(transaction)
        } catch (error) {
            setPending(false)
            if (error === "Confirmation declined by user") {
                setError(Errors.ACTION_REJECTED)
            }
            if (error.error === 'BANDWITH_ERROR') {
                setError(Errors.BANDWITH_ERROR)
            }
        }
    };

    const checkTransactionStatusOfTrc = async (txid) => {
        let receipt
        const tronWeb = window.tronWeb;
        let postReqParams = {
            "Amount": "0",
            "FormNo": "",
            "ReceiveAddress": toAddr,
            "TransactionHash": txid,
            "RecTimeStamp": "2022-01-24",
            "Response": JSON.stringify(receipt),
            "Status": "PENDING",
            "TxnId": localStorage.getItem('txnid'),
        }
        let txnid = localStorage.getItem('txnid');
        let intervalId = setInterval(async () => {
            receipt = await tronWeb.trx.getTransactionInfo(txid)
            if (receipt) {
                if (receipt?.receipt?.result === "SUCCESS") {
                    const amountData = parseInt(receipt.log[0].data, 16)
                    const actualAmount = tronWeb.fromSun(amountData)
                    postReqParams['Amount'] = actualAmount
                    const blockTimeStamp = new Date(receipt.blockTimeStamp * 1000)
                    const DateTimeStamp = blockTimeStamp.toUTCString()
                    const DateTime = DateTimeStamp.substring(0, DateTimeStamp.indexOf("GMT")) + "UTC"
                    postReqParams['RecTimeStamp'] = DateTime
                    postReqParams['Status'] = 'SUCCESS';
                    await axios.post(`${POST_URL}`, postReqParams);
                    setPending(false);
                    clearInterval(intervalId);
                    setTransactionStatus('complete')
                    setTransactionCompleteResponse(postReqParams)
                    localStorage.clear()
                } else if (receipt?.result === "FAILED") {
                    postReqParams['Status'] = 'FAIL';
                    await axios.post(`${POST_URL}`, postReqParams);
                    setPending(false);
                    clearInterval(intervalId);
                    setTransactionStatus('failed')
                    window.location.href = failedUrl.replace("{Status}", "failed").replace("{TXNID}", txnid).replace("{Amount}", value);
                    localStorage.clear()
                }
            }
        }, 5000);
    }

    const TxnId = localStorage.getItem('txnid')
    const onCancelRedirect = () => {
        window.location.href = failedUrl.replace("{Status}", "failed").replace("{TXNID}", TxnId).replace("{Amount}", value);
    }

    const checkTransactionStatus = async (tx) => {
        web3 = new Web3(window.ethereum);
        const provider = new ethers.providers.Web3Provider(window.ethereum);
        let postReqParams = {
            "Amount": "0",
            "FormNo": "",
            "ReceiveAddress": toAddr,
            "TransactionHash": tx.hash,
            "RecTimeStamp": "2022-01-24",
            "Response": JSON.stringify(tx),
            "Status": "PENDING",
            "TxnId": localStorage.getItem('txnid'),
        }

        let receipt = await provider.getTransactionReceipt(tx.hash);
        let txnid = localStorage.getItem('txnid');
        let intervalId = setInterval(async () => {
            receipt = await provider.getTransactionReceipt(tx.hash);
            if (receipt) {
                let amountData
                if (receipt.status === 1) {
                    if (tokenName === "Dcom") {
                        amountData = receipt.logs[1].data
                    }
                    else {
                        amountData = receipt.logs[0].data
                    }
                    const amountDataBn = web3.utils.toBN(amountData);
                    const amount = web3.utils.fromWei(amountDataBn);
                    postReqParams['Amount'] = amount
                    const getBlockTime = await web3.eth.getBlock(receipt.blockNumber)
                    const timestamp = getBlockTime.timestamp
                    const blockTimeStamp = new Date(timestamp * 1000)
                    const DateTimeStamp = blockTimeStamp.toUTCString()
                    const DateTime = DateTimeStamp.substring(0, DateTimeStamp.indexOf("GMT")) + "UTC"
                    postReqParams['Status'] = 'SUCCESS';
                    postReqParams['RecTimeStamp'] = DateTime
                    await axios.post(`${POST_URL}`, postReqParams);
                    setPending(false);
                    clearInterval(intervalId);
                    setTransactionStatus('complete')
                    setTransactionCompleteResponse(postReqParams)
                    localStorage.clear()
                } else if (receipt.status === 0) {
                    const timestamp = await web3.eth.getTransaction(tx.hash).blockNumber;
                    postReqParams['Status'] = 'FAIL';
                    postReqParams['RecTimeStamp'] = timestamp
                    await axios.post(`${POST_URL}`, postReqParams);
                    setPending(false);
                    clearInterval(intervalId);
                    setTransactionStatus('failed')
                    window.location.href = failedUrl.replace("{Status}", "failed").replace("{TXNID}", txnid).replace("{Amount}", value);
                    localStorage.clear()
                }
            }
        }, 5000);
    }
    const onClickHandler = async () => {
        setError();
        setPending(true);
        await processTransaction({
            setError,
            setTxs,
            ether: value,
            addr: toAddr
        });
    };
    return {
        state: { error, txs, pending, transactionStatus, transactionCompleteResponse }, action: { onCancelRedirect, onClickHandler, onClick, addToken, sendTrc20Token }
    }
}

export default useInitiateTransaction