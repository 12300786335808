import React from 'react'
import classes from './style.module.css'
const PopupModal = ({ error, onClick, addToken }) => {
    return (
        <div onClick={onClick} className={classes.popupBackground}>
            <div className={classes.popupContent}>
                <div className={classes.popupBody}>
                    <img style={{ paddingBottom: '10px' }} width='60px' height='60px' src='/error.png' alt='error img' />
                    {
                        error.code === -32603 ? <h6>{error.data.message}</h6> : <h6>{error}</h6>
                    }
                </div>
                {
                    error.code === -32603 && <button className={classes.button} onClick={addToken} >Add Token</button>
                }
            </div>
        </div >
    )
}

export default PopupModal