import { useState, useEffect } from "react";

export const useLogin = (onLogin, transactionDetails, onTronLogin) => {
    const [isConnecting, setIsConnecting] = useState(false);
    const [provider, setProvider] = useState(window.ethereum);
    const [isWalletInstalled, setIsWalletInstalled] = useState(false);
    useEffect(() => {
        setProvider(detectProvider());
    }, []);
    useEffect(() => {
        if (provider) {
            if (provider !== window.ethereum) {
                console.error(
                    "Not window.ethereum provider.  Do you have multiple wallets installed ?"
                );
            }
            setIsWalletInstalled(true);
        }
    }, [provider]);

    const detectProvider = () => {
        let provider;
        if (window.ethereum) {
            provider = window.ethereum;
        } else if (window.web3) {
            if (window.web3.currentProvider.isTrust) {
                provider = window.web3.currentProvider;
            }
        } else {
            console.warn("No Ethereum browser detected! Check out MetaMask");
        }
        return provider;
    };

    const cancelTransaction = () => {
        window.location.href = transactionDetails?.FailerURL.replace("{Status}", "failed").replace("{TXNID}", transactionDetails?.TransactionId).replace("{Amount}", transactionDetails?.Amount);
    }

    const onLoginHandler = async () => {
        setIsConnecting(true);
        let accounts;
        try {
            accounts = await provider.request({
                method: "eth_requestAccounts"
            });
        } catch (err) {
            console.error("Failed to get accounts", err);
            setIsConnecting(false);
            return;
        }
        setIsConnecting(false);
        onLogin(provider, accounts[0]);
    };

    const onTronConnect = async () => {
        let tronWeb;
        let accounts;
        setIsConnecting(true);
        if (window.tronLink.ready) {
            tronWeb = window.tronLink.tronWeb;
            accounts = window.tronLink.tronWeb.defaultAddress.base58
            setIsWalletInstalled(true)
        } else {
            const res = await window.tronLink.request({ method: 'tron_requestAccounts' });
            if (res.code === 200) {
                tronWeb = window.tronLink.tronWeb;
                accounts = window.tronLink.tronWeb.defaultAddress.base58
                setIsWalletInstalled(true)
            }
            setIsConnecting(false);
        };
        onTronLogin(accounts, tronWeb)
    }

    return {
        state: {
            isConnecting, isWalletInstalled
        }, action: { onLoginHandler, cancelTransaction, detectProvider, onTronConnect }
    }

}
