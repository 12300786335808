import Web3 from "web3";
export const NETWORKS = {
  1: "Ethereum Main Network",
  137: 'Poly Network',
  56: 'Binance Smart Chain',
  100: 'Tron Network'
};

const tronChainId = 1
export const tronNetwork = {
  chainId: Web3.utils.toHex(tronChainId),
  chainName: "Tron Mainnet",
  nativeCurrency: {
    name: "TRX",
    symbol: "TRX",
    decimals: 18
  },
  rpcUrls: ["https://api.trongrid.io"],
  blockExplorerUrls: ["https://tronscan.org"]
}

const polyChainId = 137
export const polyNetwork = {
  chainId: Web3.utils.toHex(polyChainId),
  chainName: "Polygon (formerly Matic)",
  nativeCurrency: {
    name: "MATIC",
    symbol: "MATIC",
    decimals: 18
  },
  rpcUrls: ["https://rpc-mainnet.maticvigil.com/", "https://rpc-mainnet.polygon.io/"],
  blockExplorerUrls: ["https://explorer.matic.network/", "https://explorer.polygon.io/"]
}

const bnbChainId = 56
export const bnbNetwork = {
  chainId: Web3.utils.toHex(bnbChainId),
  chainName: "Binance Smart Chain",
  nativeCurrency: {
    name: "BNB",
    symbol: "BNB",
    decimals: 18
  },
  rpcUrls: ["https://bsc-dataseed.binance.org/", "https://bsc-rpc.binance.org/"],
  blockExplorerUrls: ["https://bscscan.com"]
}







