import React from 'react'
import Login from '../components/Login/Login'
import TransactionDetails from '../components/TransactionDetails/TransactionDetails'
import Card from '../components/Card/Card'

const MainDetails = ({ isConnected, onLogin, onLogout, currentAccount, getCurrentNetwork, chainId, onTronLogin, transactionDetails }) => {
    return (
        <>
            <Card logo={transactionDetails?.LogoURL}>
                {!isConnected && <Login transactionDetails={transactionDetails} onTronLogin={onTronLogin} onLogin={onLogin} onLogout={onLogout} />}
                {isConnected && (
                    <>
                        <TransactionDetails
                            currentAccount={currentAccount}
                            currentNetwork={getCurrentNetwork(chainId)}
                            transactionDetails={transactionDetails}
                            isConnected={isConnected}
                            onLogout={onLogout}
                        />
                    </>
                )}
            </Card>
        </>
    )
}

export default MainDetails