import React from 'react'
import classes from './style.module.css'

const Loader = () => {
    return (
        <div className={classes.loadingOverlay}>
            <div className={classes.loadingSpinner}>
                <div className={classes.spinner}></div>
            </div>
        </div >
    )
}
export default Loader