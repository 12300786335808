import { useState, useEffect } from "react";
import { NETWORKS, bnbNetwork, polyNetwork, tronNetwork } from "../constant/constant";
import Web3 from "web3";
import axios from "axios";

const UseTransaction = () => {
  let txnParams = window.location.search;
  let params = new URLSearchParams(txnParams);
  let TXNID = params.get('txnid');
  localStorage.setItem('txnid', TXNID);

  const [isConnected, setIsConnected] = useState(false);
  const [currentAccount, setCurrentAccount] = useState(null);
  const [chainId, setChainId] = useState(null);
  const [transactionDetails, setTransactionDetails] = useState(null)
  const GET_URL = process.env.REACT_APP_GET_API_URL

  useEffect(() => {
    async function getTransactionDetails() {
      const response = await axios.get(`${GET_URL}${TXNID}`);
      const { data } = response
      if (data.Network === 'bep20') {
        setTransactionDetails({ ...data, networkConfig: bnbNetwork })
      }
      if (data.Network === "erc20") {
        setTransactionDetails({ ...data, networkConfig: polyNetwork })
      }
      if (data.Network === "trc20") {
        setTransactionDetails({ ...data, networkConfig: tronNetwork })
      }
    }
    getTransactionDetails()
  }, []);


  const onLogin = async (provider) => {
    const web3 = new Web3(provider);
    const accounts = await web3.eth.getAccounts();
    const chainId = await web3.eth.getChainId();
    if (accounts.length === 0) {
      console.log("Please connect to MetaMask!");
    } else if (accounts[0] !== currentAccount) {
      setChainId(chainId);
      setCurrentAccount(accounts[0]);
      setIsConnected(true);
    }
  };

  const onTronLogin = (account, tronweb) => {
    setCurrentAccount(account);
    setIsConnected(true);
    setChainId(100)
  }

  const onLogout = () => {
    setIsConnected(false);
    setCurrentAccount(null);
  };

  const getCurrentNetwork = (chainId) => {
    return NETWORKS[chainId];
  };

  return {
    state: {
      isConnected,
      currentAccount,
      chainId,
      transactionDetails
    },
    action: { onLogin, onTronLogin, onLogout, getCurrentNetwork }
  }
}

export default UseTransaction