import './style.module.css'
import useInitiateTransaction from "../../hooks/useInitiateTransaction";
import Loader from "../Loader/Loader";
import PopupModal from "../Modal/PopupModal";
import TxSucessModal from "./TxSucessModal";
const InitiateTransaction = ({ value, toAddr, requiredChainId, requiredNetwork, ContractAddress, CurrentAccount, tokenName, jsonAbi, successUrl, failedUrl, decimalPlaces, isConnected, onLogout, Network }) => {
  const { state: { error, pending, transactionStatus, transactionCompleteResponse }, action: { onClickHandler, onClick, addToken, onCancelRedirect, sendTrc20Token } } = useInitiateTransaction(value, CurrentAccount, toAddr, requiredChainId, requiredNetwork, ContractAddress, tokenName, jsonAbi, successUrl, failedUrl, decimalPlaces)
  return (
    <>
      {
        pending ? <Loader /> : <>
          <hr />
          <div className="btnWrapper">
            {
              pending === false ?
                <button type="button"
                  className="btn btn-outline-success"
                  onClick={Network === "trc20" ? sendTrc20Token : onClickHandler}>
                  Pay now
                </button> : ''
            }
            {
              isConnected && <button
                onClick={onLogout}
                className="btn btn-outline-primary"
                type="button"
              >
                Wallet Disconnect
              </button>
            }
            <button
              type="button"
              className="btn btn-outline-danger"
              onClick={onCancelRedirect} >
              Cancel
            </button>
          </div>
          {
            !error ? null : <PopupModal onClick={onClick} addToken={addToken} error={error} />
          }
          {
            transactionStatus === 'complete' && < TxSucessModal transactionCompleteResponse={transactionCompleteResponse} />
          }
          {
            transactionStatus === 'failed' && <h4>Your transaction is failed</h4>
          }
        </>
      }
    </>
  );
}
export default InitiateTransaction;
