import './styles.css'
const Card = (props) => {
  return (
    <div className="container mt-5">
      <div className="row">
        <div className="col-sm-3" aligh="center"></div>
        <div className="col-sm-6 text-center" aligh="center">
          <div className="box">
            <div className="text-center logobox">
              <img src={props.logo} alt="" width="200" />
            </div>
            <hr />
            {props.children}
          </div>
        </div>
      </div>
    </div >
  );
};

export default Card;