import axios from 'axios'
import { useEffect, useState } from 'react'

const useAbi = (jsonAbi) => {
    const [abiJson, setAbiJson] = useState()
    const getJsonAbi = async () => {
        try {
            const response = await axios.get(jsonAbi)
            const { data } = response
            if (data.status === "1") {
                setAbiJson(JSON.parse(data.result))
            }
        }
        catch (err) {
            console.log(err)
        }
    }
    useEffect(() => {
        getJsonAbi()
    }, [])

    return {
        state: { abiJson }
    }
}

export default useAbi