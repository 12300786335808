import InitiateTransaction from "../InitiateTransaction/InitiateTransaction";
import useAbi from "../../hooks/useAbi";

const TransactionDetails = ({ currentNetwork, transactionDetails, currentAccount, isConnected, onLogout }) => {
  const { state: { abiJson } } = useAbi(transactionDetails.JsonApi)
  if (transactionDetails) {
    return (
      <>
        <h2>Transaction Details </h2>
        <div style={{ fontSize: '1em', wordWrap: 'break-word' }} className="d-flex flex-column" >
          <p>Current Network: {currentNetwork}</p>
          <p>From Address: {currentAccount}</p>
          <p>To Address: {transactionDetails.ReceiveAddress}</p>
          <p>Transaction Amount: {transactionDetails.Amount}</p>
          <p>Token Name: {transactionDetails.TokenName}</p>
        </div>
        <InitiateTransaction
          CurrentAccount={currentAccount}
          Network={transactionDetails.Network}
          ContractAddress={transactionDetails.ContractAddress}
          toAddr={transactionDetails.ReceiveAddress}
          value={transactionDetails.Amount}
          requiredChainId={transactionDetails.networkConfig.chainId}
          requiredNetwork={transactionDetails.networkConfig}
          tokenName={transactionDetails.TokenName}
          jsonAbi={abiJson}
          successUrl={transactionDetails.SuccessURL}
          failedUrl={transactionDetails.FailerURL}
          decimalPlaces={transactionDetails.Decimal}
          isConnected={isConnected}
          onLogout={onLogout}
        />
      </>
    )
  }
};
export default TransactionDetails;